<template>
	<div @click="menu = null">
		<v-row class="mx-3 mt-1" align="center" no-gutters v-if="currentFolder !== null">
			<v-col cols="auto">
				<div class="rounded-lg">
					<Button
							iconVal="mdi-arrow-left"
							tile
							iconPosition="center"
							small
							carre
							:disabled="history_index === 0"
							color="primary"
							:toolTip="$t('single_trad.Dialog.fast_edit')"
							:click="() => goBack()"
							classes="rounded-l-lg"
					/>
					<v-divider vertical/>
					<Button
							iconVal="mdi-arrow-right"
							tile
							iconPosition="center"
							small
							carre
							:disabled="history.length - (history_index + 1) <= 0"
							color="primary"
							:toolTip="$t('single_trad.Dialog.fast_edit')"
							:click="() => goForward()"
					/>
					<v-divider vertical/>
					<Button
							tile
							small
							carre
							color="secondary"
							:disabled="currentFolder.parent === null"
							:click="() => { currentFolder = findInFolders(currentFolder.parent) }"
							:loading="loading"
							iconVal="mdi-arrow-up"
							classes="rounded-r-lg"
					/>
				</div>
			</v-col>
			<v-col class="pl-3">
				<v-row align="center" no-gutters>
					<v-col>
						<TextField
								v-model="pathSearch"
								appendIcon="mdi-arrow-right"
								@enter="() =>  {getPath(pathSearch, folders); initSearch()}"
						        :iconClick="() => {getPath(pathSearch, folders); initSearch()}"/>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<div class="pa-3" v-if="items.length > 0 && currentFolder !== null">
			<v-row class="cell2 rounded-lg overflow-hidden" style="min-height: 300px;" no-gutters>
				<v-col class="pa-2" style="min-width:250px; max-width: 250px;">
						<NestedTest
								v-model="folders"
								@changeCurrentFolder="(e) => { currentFolder = e; history.push(currentFolder); history_index++ }"
								:current="currentFolder"/>
				</v-col>

				<v-divider vertical />

				<v-col v-if="currentFolder !== null" @click="selectedFiles = []" @contextmenu="(e) => openMenu(e)">
					<v-row class="mx-0 pa-1" v-if="currentFolder.folders.length > 0">
						<v-col
								v-for="(folder, i) in  currentFolder.folders"
								:key="i+'folder'"
								@dblclick="currentFolder = folder; currentFolderCopy = {...currentFolder}"
								class="text-center pa-1"
								cols="auto"
						>
							<v-hover v-slot:default="{ hover }">
								<div class="pa-2 cursor-pointer rounded-lg transition-cubic" style="min-width: 90px; max-width: 90px" :class="hover ? 'cell':''">
									<v-icon class="mt-1">mdi-folder</v-icon>
									<div class="caption-1 noselect pt-2 pb-1 px-1">{{ folder.name | firstCapital }}</div>
								</div>
							</v-hover>
						</v-col>

					</v-row>
					

					<draggable
							class="row mx-0 pa-1"
							:group="{ name: 'files', pull: false, put:false}"
							v-model="currentFolder.files"
							style="min-height: 100px;"
							:move="(e) => selectFile(e)"
							@end="(e) => moveFile(e)"
							:sort="false"
							ghost-class="ghost"
							disabled
					>
						
						<v-col
								v-for="(file, i) in currentFolder.files"
								:key="file.id"
								class="text-center pa-1"
								cols="auto"
						>
							<v-hover v-slot:default="{ hover }">
								<div
										style="position: relative; min-width: 90px; max-width: 90px"
										class="pa-2 cursor-pointer rounded-lg transition-cubic"
										:class="hover ? 'cell':''"
										:style="selectedFiles.find(f => f.id === file.id) ? 'border: 1px solid var(--v-cell4-base)': 'border: 1px solid var(--v-cell2-base)'"
										@contextmenu.stop="(e) => openMenu(e, file)"
										@click.stop="menu = null; selectedFiles.find(f=> f.id === file.id) ? edit ? '' : selectedFiles = selectedFiles.filter(f => f.id !== file.id) : selectedFiles.push(JSON.parse(JSON.stringify(items.find(f => f.id === file.id))))"
										@dblclick="openDownloads(file)"
								>
									<v-icon>mdi-file-outline</v-icon>
									<div class="caption-1 noselect pt-3 pb-1 px-1">{{ currentFolder.files[i].name }}</div>
								</div>
							</v-hover>
						</v-col>
					</draggable>
				</v-col>
			</v-row>
		</div>

		<v-row v-else-if="ready && totalItems === 0" class="py-4" justify="center">
			<v-col cols="auto" class="text-center">
				<v-icon class="mb-4" size="30">mdi-block-helper</v-icon>
				<div class="paragraph">{{ $tc('single_trad.Table.no_data', 1) }}</div>
			</v-col>
		</v-row>

		<div v-else style="display: flex; align-items: center; justify-content: center" class="py-8">
			<v-progress-circular indeterminate size="40" color="primary"/>
		</div>

		<div class="cell rounded-lg elevation-2 overflow-hidden" v-show="menu !== null" style="position: fixed; z-index: 1;" :style="menu !== null ? {top: menu.y + 'px', left: menu.x + 'px'}: {}">
			<v-list class="py-0 transparent">
				<v-list-item link @click="items2paste = JSON.parse(JSON.stringify(selectedFiles))" :disabled="!item2edit.id && selectedFiles.length === 0">
					<v-list-item-icon class="mr-1">
						<v-icon small :color="!item2edit.id && selectedFiles.length === 0 ? 'cell3':''">mdi-content-cut</v-icon>
					</v-list-item-icon>
					<v-list-item-content class="paragraph" :class="!item2edit.id && selectedFiles.length === 0 ? 'cell3--text':''">{{ $t('FileBrowser.cut') }}</v-list-item-content>
				</v-list-item>
				<!--<v-list-item link @click="items2paste = selectedFiles" :disabled="!item2edit.id && selectedFiles.length === 0">
					<v-list-item-icon class="mr-1">
						<v-icon small :color="!item2edit.id && selectedFiles.length === 0 ? 'cell3':''">mdi-content-copy</v-icon>
					</v-list-item-icon>
					<v-list-item-content class="paragraph" :class="!item2edit.id && selectedFiles.length === 0 ? 'cell3&#45;&#45;text':''">{{ $t('FileBrowser.copy') }}</v-list-item-content>
				</v-list-item>-->
				<v-list-item link @click="patchItems(null)" :disabled="items2paste.length === 0">
					<v-list-item-icon class="mr-1"><v-icon small :color="items2paste.length === 0 ? 'cell3':''">mdi-content-paste</v-icon></v-list-item-icon>
					<v-list-item-content class="paragraph" :class="items2paste.length === 0 ? 'cell3--text':''">{{ $t('FileBrowser.paste') }}</v-list-item-content>
				</v-list-item>
				<v-list-item link @click="edit_name_dialog = true" v-if="item2edit.id">
					<v-list-item-icon class="mr-1"><v-icon small>mdi-form-textbox-password</v-icon></v-list-item-icon>
					<v-list-item-content class="paragraph">{{ $t('FileBrowser.rename') }}</v-list-item-content>
				</v-list-item>
				<v-list-item link @click="pevents.notify('fast-edit', item2edit)" v-if="item2edit.id">
					<v-list-item-icon class="mr-1"><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
					<v-list-item-content class="paragraph">{{ $t('single_trad.Dialog.fast_edit') }}</v-list-item-content>
				</v-list-item>
				<v-list-item link v-if="item2edit.id" @click="openDownloads(item2edit)">
					<v-list-item-icon class="mr-1"><v-icon small>mdi-download</v-icon></v-list-item-icon>
					<v-list-item-content class="paragraph">{{ $t('FileBrowser.download') }}</v-list-item-content>
				</v-list-item>
				<v-list-item link v-if="item2edit.id" @click="$router.push('/dashboard/' + path + '/' + item2edit.id)">
					<v-list-item-icon class="mr-1"><v-icon small>mdi-open-in-new</v-icon></v-list-item-icon>
					<v-list-item-content class="paragraph">{{ $t('FileBrowser.page') }}</v-list-item-content>
				</v-list-item>
				<v-list-item link v-if="item2edit.id" @click="openDownloads(item2edit)">
					<v-list-item-icon class="mr-1"><v-icon small>mdi-eye</v-icon></v-list-item-icon>
					<v-list-item-content class="paragraph">{{ $t('FileBrowser.open') }}</v-list-item-content>
				</v-list-item>
				<v-list-item link @click="pevents.notify('delete-item-item', item2edit)" v-if="item2edit.id">
					<v-list-item-icon class="mr-1"><v-icon small>mdi-delete</v-icon></v-list-item-icon>
					<v-list-item-content class="paragraph">{{ $t('FileBrowser.trash') }}</v-list-item-content>
				</v-list-item>
			</v-list>
		</div>

		<Dialog
				v-model="edit_name_dialog"
				:title="$t('single_trad.Dialog.fast_edit')"
				@close="pevents.notify('close-fast'); edit_name_dialog = false"
		>
			<template v-slot:content>
				<FieldGroup
						:pevents="pevents"
						class="mx-3 mb-2"
						v-model="item2edit"
						:fields="{name: allFields['name']}"
						mode="fast-edition"
						:path="path"
						:pathId="Object.keys(item2edit).length > 0 ? item2edit.id : ''"
						@close="edit_name_dialog = false"
				/>
			</template>
		</Dialog>

	</div>
</template>

<script>
	export default {
		name: "FileBrowser",
		props: ['actions', 'fields', 'allFields', 'ordering', 'path', 'pevents', 'routeChildren', 'methods'],
		components: {
			draggable: () => import('vuedraggable'),
			NestedTest: () => import('@/components/filebrowser/Nested.vue'),
			Button: () => import('@/components/ui/Button.vue'),
			Actions: () => import('@/components/Actions.vue'),
			Dialog: () => import('@/components/ui/Dialog.vue'),
			FieldGroup: () => import('@/components/FieldGroup.vue'),
			Field: () => import('@/components/Field.vue'),
			TextField: () => import('@/components/fields/TextField.vue')
		},
		data() {
			return {
				idToDrag: null,
				currentFolder: null,
				currentFolderCopy: null,
				totalItems: 0,
				items: [],
				folders: [],
				filters: {},
				history: [],
				history_index: 0,
				ready: false,
				confirm_dialog: false,
				edit_dialog: false,
				selectedFiles: [],
				item2edit: {},
				items2paste: [],
				edit: false,
				events: null,
				loading: false,
				mode: null,
				pathSearch: '',
				searchFolder: {name: null, path: null, files: [], folders: [], parent: null},
				menu: null,
				edit_name_dialog: false,
				search: ''
			}
		},
		watch: {
			currentFolder(val) {
				this.pathSearch = val.path
				this.selectedFiles = []
			},
			items() {
				this.folders = []
				this.getTree()
			},
			selectedFiles(val) {
				this.pevents.notify('selection', val)
			}
		},
		methods: {
			openMenu(e, file){
				e.preventDefault()

				this.menu = {x: e.clientX, y: e.clientY}
				this.item2edit = typeof file !== 'undefined' ? file : {}

				if(!this.selectedFiles.find(e => e.name === file.name) && typeof file !== 'undefined'){
					this.selectedFiles.push(file)
				}
			},
			findInFoldersChildren(folder, child){
				if(child.name === folder.name){
					return child
				}
				else {
					for(let sf of child.folders)
						return this.findInFoldersChildren(folder, sf)
				}
			},
			findInFolders(folder){
				for(let f of this.folders) {
					if (f.name === folder.name) {
						return f
					}
					else {
						for (let sf of f.folders)
							return this.findInFoldersChildren(folder, sf)
					}
				}
			},
			computeLink(endpoint) {
				let res = []

				if (endpoint) {
					for (let i of endpoint.split('/')) {
						if (i.match(':')) {
							let vat = i.replace(':', '')
							res.push(this.$route.params[vat])
						} else {
							res.push(i)
						}
					}
				}

				return '/dashboard/' + res.join('/')
			},
			goBack() {
				this.history_index--
				this.currentFolderCopy = JSON.parse(JSON.stringify(this.currentFolder))
			},
			goForward() {
				this.history_index++
				this.currentFolder = this.history[this.history_index]
				this.currentFolderCopy = JSON.parse(JSON.stringify(this.currentFolder))
			},
			getPath(val, folders) {
				folders.forEach(folder => {
					if (!val.endsWith('/')) {
						val = val + '/'
						if (!val.startsWith('/')) {
							val = '/' + val
						}
					}

					if (folder.path === val) {
						this.searchFolder = {...folder}
					} else {
						if (folder.folders.length > 0) {
							this.getPath(val, folder.folders)
						}
					}
				})
			},
			initSearch() {
				if(this.search === '')
					this.currentFolderCopy = JSON.parse(JSON.stringify(this.currentFolder))
				this.currentFolder = Object.assign({}, this.searchFolder)
				this.searchFolder.folders = []
				this.searchFolder.files = []
			},
			async getSearch(val, folders) {
				folders.forEach(f => {
					if (f.name.toLowerCase().search(val.toLowerCase()) > -1) {
						this.searchFolder.folders.push(f)
					}
					f.files.forEach(file => {
						if (file.name.toLowerCase().search(val.toLowerCase()) > -1) {
							this.searchFolder.files.push(file)
						}
					})
					f.folders.forEach(folder => {
						if (folder.name.toLowerCase().search(val.toLowerCase()) > -1) {
							this.searchFolder.folders.push(folder)
						}

						if (folder.folders.length > 0) {
							this.getSearch(val, folder.folders)
						}
						if (folder.files.length > 0) {
							folder.files.forEach(file => {
								if (file.name.toLowerCase().search(val.toLowerCase()) > -1) {
									this.searchFolder.files.push(file)
								}
							})
						}
					})
				})
			},
			getChildren(route, index, folder, e, path, parent) {
				let i = index
				let path2 = path + route[i] + '/'
				let folders = []
				let files = []
				let current = null

				if (!folder.find(f => f.name === route[i])) {
					let new_parent = JSON.parse(JSON.stringify(parent))

					if(new_parent.parent !== null && typeof new_parent.parent.parent !== 'undefined')
						delete new_parent.parent.parent

					current = {name: route[i], path: path2, files: [], folders: [], parent: new_parent}
					folder.push(current)
					this.$set(current, 'folders', folders)
				} else {
					current = folder.find(f => f.name === route[i])
				}

				i++

				current['profondeur'] = parent.profondeur + 1

				if (typeof route[i] === 'undefined') {
					if (!current.files.length > 0) {
						files.push(e)
						this.$set(current, 'files', files)
					} else {
						current.files.push(e)
					}

				} else if (typeof route[i] !== 'undefined') {
					this.getChildren(route, i, current.folders, e, path2, current)
				}

			},
			getTree() {
				let index = 0
				let path = '/'
				let current = {name: '', path: path, files: [], folders: [], parent: null, profondeur: 0} // parent: a voir si c'est trop lourd de mettre direct l'objet
				let files = []

				this.folders.push(current)

				this.currentFolder = this.folders[0]
				this.history.push(this.currentFolder)

				this.items.forEach(e => {
					let route = e.location.split('/')

					if (route[index] === '') {
						files.push(e)
						this.$set(this.folders[index], 'files', files)
					} else {
						this.getChildren(route, index, this.folders[index].folders, e, path, current)
					}
				})
			},
			getItems() {
				this.ready = false

				this.$wsc.getList(this.path, this.filters, success => {
					if (this.filters['page_size'] === 0) {
						this.items = success.results
						this.totalItems = success.length
					} else {
						this.items = success
						this.totalItems = success.length
					}

					this.getTree()
					this.ready = true

				}, fail => {

				})
			},
			patchItems(path) {
				this.loading = true

				if (path === null) {
					path = this.currentFolder.path
				}

				let data = this.items2paste.map((f) => {
					let name = ""
					if (this.items.find(e => e.name === f.name && e.location === path && e.id === f.id)) {
						let index = this.items2paste.findIndex(e => e.id === f.id)
						this.items2paste.splice(index, 1)
						return null
					} else {
						if (this.items.find(e => e.name === f.name && e.location === path)) {
							name = f.name + " new"
						} else {
							name = f.name
						}
						return {
							id: f.id,
							location: path,
							name: name
						}
					}
				})

				data.forEach(d => {
					if (d !== null) {
						this.$wsc.patchItem(this.path, d.id, d, success => {
							this.items2paste = []
							this.selectedFiles = []
							let index = this.items.findIndex(e => e.id === parseInt(success.id))

							this.items.splice(index, 1, success)

							this.loading = false
						}, fail => {
							this.loading = false
						})
					} else {
						this.loading = false
						this.$store.dispatch('snackbar/success', this.$t('snackbar.FileBrowser.already'))
					}
				})
			},
			openDownloads(file) {
				let backendURL = this.$store.getters['config/backend_url']
				/*this.selectedFiles.forEach(file => {
					window.open(backendURL + file.file, '_blank');
				})*/
				window.open(backendURL + file.file, '_blank');
			},
			openPages() {
				this.selectedFiles.forEach(file => {
					let routeData = this.$router.resolve({path: '/dashboard/' + this.path + '/' + file.id})
					window.open(routeData.href, '_blank')
				})
			},
			createItem() {
				this.loading = true
				let data = this.items2paste.map((f) => {
					let name = ""
					if (this.items.find(e => e.name === f.name && e.location === this.currentFolder.path)) {
						name = f.name + " copy"
					} else {
						name = f.name
					}
					return {
						account: [f.account.id],
						file: f.file,
						location: this.currentFolder.path,
						name: name
					}
				})
				if (this.items2paste.length === 1) {
					this.$wsc.createItem(this.path, data[0], success => {
						this.loading = false
						this.items2paste = []

					}, fail => {
						this.loading = false
					})
				}
				if (this.items2paste.length > 1) {
					this.$wsc.createBatch(this.path, data, success => {
						success.succeded.forEach(e => {
						})

						this.loading = false
						this.$store.dispatch('snackbar/success', this.$t('snackbar.Item.createAll.success'))
					}, fail => {
						this.loading = false
						this.$store.dispatch('snackbar/error', this.$t('snackbar.Item.createAll.fail'))
					})
				}

			},
			deleteItems() {
				this.loading = true

				let data = this.selectedFiles.map(e => e.id)

				this.$wsc.deleteBatch(this.path, data, success => {
					success.succeded.forEach(e => {
						let index = this.items.findIndex(l => l.id === e.id)

						this.items.splice(index, 1)
						this.totalItems = this.items.length

						let index2 = this.selectedFiles.findIndex(l => l.id === e.id)

						this.selectedFiles.splice(index2, 1)
					})

					this.confirm_dialog = false
					this.loading = false
				}, fail => {
					this.loading = false
				})
			},
			selectFile(event){
				console.log(event, event.draggedContext.element)
				this.items2paste = [event.draggedContext.element]
				// event.to.style = 'border: 1px solid #f00'
			},
			moveFile(event) {
				//console.log(event.to.parentElement.id)
				if (event.to.parentElement.id) {
					let path = event.to.parentElement.id
					this.patchItems(path)
				}
			},
			setUpHooks() {
				this.events = new this.$NVEvent("FileBrowser")

				this.events.wait('child-saved', (field, success) => {
					let index = this.selectedFiles.findIndex(l => l.id === success.id)
					this.selectedFiles.splice(index, 1)
					if (this.selectedFiles.length === 0)
						this.edit = false
				})
				this.events.wait('child-closed', (name, id ) => {
					let index = this.selectedFiles.findIndex(l => l.id === id)
					this.selectedFiles.splice(index, 1)

					if (this.selectedFiles.length === 0)
						this.edit = false
				})

				this.pevents.wait('new-item', (item) => {
					this.items.unshift(item)
					this.totalItems++
				})

				this.pevents.wait('delete-item', success => {
					let index = this.items.findIndex(e => e.id === parseInt(success.id))

					this.edit_dialog = false
					this.items.splice(index, 1)
					this.totalItems = this.items.length
				})

				this.pevents.wait('delete-items', success => {
					success.succeded.forEach(e => {
						let index = this.items.findIndex(l => l.id === e.id)
						this.items.splice(index, 1)
						this.totalItems = this.items.length

						let index2 = this.selectedFiles.findIndex(l => l.id === e.id)
						this.selectedFiles.splice(index2, 1)
					})
				})

				this.pevents.wait('search', val => {
					if(val === ''){
						this.currentFolder = this.currentFolderCopy
						this.search = val
					}
					else {
						this.getSearch(val, this.folders).then(() => {
							this.initSearch()
							this.search = val
						})
					}
				})

				this.pevents.wait('search-clear', val => {
					this.search = ''
					this.currentFolder = this.currentFolderCopy
				})
			},
			clickListenner(){
				this.menu = null
			}
		},
		created() {
			this.getItems()
			this.setUpHooks()
		},
		mounted(){
			window.addEventListener('click', this.clickListenner)
		},
		beforeDestroy() {
			window.removeEventListener('click', this.clickListenner)
		}
	};
</script>
<style scoped>
/* .ghost, .ghost > *, .ghost > * > *{
	pointer-events: none;
	color: yellow;
	display:none;
}
div:has(> div.ghost) {
	pointer-events: none;
} */
</style>
